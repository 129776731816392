/* eslint-disable no-console */
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { object, string } from 'yup';

import {
  Field,
  Input,
  PasswordInput,
  LinkButton,
  Wordmark,
  VideoBackground,
  Indicator,
  Button,
} from 'components/common';

import { SigninPayload } from 'modules/auth/types';
import { useAuth } from 'modules/auth/useAuth';

import { ROUTES } from 'routing/constants';

export const LoginForm = () => {
  const { login, errorText, isLoading } = useAuth();
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.HOME);
    }
  }, [navigate, isLoggedIn]);

  const schema = object()
    .shape({
      username: string().email('Please enter a valid email address.').required('Email is required'),
      password: string().required('Password is required'),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SigninPayload>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const handleFormSubmit = handleSubmit(login);

  return (
    <VideoBackground>
      <div className="w-128 p-10 bg-white rounded-xl shadow flex-col justify-start items-center inline-flex">
        <div className="stretch-to-form gap-12">
          <Wordmark variant="gradient-dark" className="w-40 h-7" />
          <form onSubmit={handleFormSubmit} className="w-full">
            <div className="stretch-to-form gap-10">
              <h1 className="self-stretch text-heading-4xl font-bold leading-10 text-secondary-black">
                Login to your account.
              </h1>
              <div className="stretch-to-form gap-3">
                <div className="stretch-to-form gap-6">
                  <Field
                    label={'Email'}
                    render={({ field, fieldState: { invalid } }) => (
                      <Input {...field} invalid={invalid} />
                    )}
                    name="username"
                    control={control}
                    error={errors.username?.message}
                  />

                  <Field
                    label={'Password'}
                    render={({ field, fieldState: { invalid } }) => (
                      <PasswordInput {...field} invalid={invalid} />
                    )}
                    tooltip={'Password'}
                    name="password"
                    control={control}
                    error={errors.password?.message}
                    secondaryLabel={
                      <Link to={ROUTES.FORGOT_PASSWORD} className="flex items-center">
                        <LinkButton size="sm" className="h-auto py-0">
                          Forgot password
                        </LinkButton>
                      </Link>
                    }
                  />
                </div>
                <div className="stretch-to-form gap-3">
                  {errorText && (
                    <Indicator variant="danger" size="lg">
                      {errorText}
                    </Indicator>
                  )}
                </div>
              </div>
              <Button
                size="lg"
                variant="primary"
                disabled={!isValid}
                isLoading={isLoading}
                className="w-full"
                onClick={handleFormSubmit}
              >
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </VideoBackground>
  );
};
